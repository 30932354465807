<template>
  <div >
      <div class="search" @keyup.enter="getList">
        <el-input placeholder="请输入内容" v-model="searchValue" class="input-with-select">
          <el-button slot="append" icon="el-icon-search"  @click="getList"></el-button>
        </el-input>
      </div>

    <div
        class="container"
        v-loading="loading">
      <div
          class="box"
          v-for="i in contents.records"
          :key="i.id">
        <SchoolList :info="i"></SchoolList>
      </div>
      <div
          class="box"
          v-show="contents.records.length % 3 != 0"
          v-for="(i, index) in 3 - (contents.records.length % 3)"
          :key="index"></div>
      <div class="paging">
        <el-pagination
            @current-change="handleCurrentChange"
            :page-size="parameter.size"
            :current-page.sync="parameter.current"
            :background="true"
            layout="total, prev, pager, next"
            :total="contents.total"
            v-if="contents.total!==0"></el-pagination>
      </div>
    </div>
  </div>

</template>

<script>
import {getDeptList} from '@/api/party/index';
import SchoolList from '../components/SchoolList.vue';

export default {
  name: 'index',
  data: () => {
    return {
      parameter: {
        current: 1,
        size: 9,
      },
      searchValue: "",
      contents: {records: []},
      loading: false,
      totals: [],
    };
  },
  components: {
    SchoolList,
  },
  created() {
    //获取学校列表
    this.getList();
  },
  methods: {
    async getList() {
      this.loading = true;
      this.parameter.deptName = this.searchValue
      const {data} = await getDeptList(this.parameter);
      this.contents = data;
      this.loading = false;
    },
    handleCurrentChange(page) {
      sessionStorage.setItem('currentPage', page)
      this.parameter.current = page;
      this.getList();
    },
    handleSelect(item, index) {
      this.defaultItem = index;
      this.parameter.contentId = item.id;
      this.parameter.current = 1;
      this.getList();
    },
    getCurrentPage() {
      // 获取详情页存储的detail，做if判断
      if (sessionStorage.getItem('detail')) {
        // 如果detail存在就获取列表页缓存的数据，并更新
        this.parameter.current = Number(sessionStorage.getItem('currentPage'));
        this.getList();
      } else {
        this.parameter.current = 1;
        // 从其它页面第一次进入列表页时，清理掉缓存的数据
        sessionStorage.removeItem('currentPage')
      }
    }
  },
  mounted() {
    this.getCurrentPage();
    // 销毁detail
    sessionStorage.removeItem('detail')
  }
};
</script>

<style
    lang="scss"
    scoped>

.search {
  position: relative;
  margin-top: 15px;
  height: 30px;
  div{
    width: 394px;
    position: absolute;
    right: 0;
  }
}
.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 12px 0 30px 0;

  .box {
    width: 32.8%;
    margin-top: 10px;
  }

  .paging {
    margin-top: 30px;
    text-align: center;
    width: 100%;
  }
}
</style>
